import React from 'react'
import { Container } from 'reactstrap'
import { Link } from 'gatsby'
import styled from 'styled-components'

import Layout from '~components/Layout'
import Main from '~components/layouts/Main'
import HeroSection from '~components/layouts/marketing/HeroSection'
import { RepairServices } from '~components/Home/RepairServices'
import FeaturedServices from '~components/RepairServices/FeaturedServices'
import SEO from '~components/seo'

const Lead = styled.h2`
  font-size: 16px !important;
  line-height: 1.25 !important;
`

const RepairServicesIndexPage = () => (
  <Layout>
    <SEO title="Repair Services" pathname="/repair-services" description="Services we offer for all your leather repair and care needs" />
    <Main>
      <HeroSection title={'Repair & Care'}
        mobileImg={'https://leatherspa.imgix.net/RepairServiceHome.jpg?fit=scale&w=992&fm=jpg'}
        desktopImg={'https://leatherspa.imgix.net/RepairServiceHome.jpg?fit=scale&w=1360&fm=jpg'}>
        <Lead>With an array of customizable services that sets us apart, we continue to perfect the art of leather care.</Lead>
        <p>Leather Spa provides extensive leather repair and care services worldwide. Combining traditional Old World craftsmanship with innovative technology, Leather Spa offers premium quality care for all of your most treasured leather accessories.</p>
        <p>Select one of the icons below to see what repair services we offer for that particular item:</p>
      </HeroSection>
      <Container className="mt-5">
        <RepairServices />
        <div className="text-center">
          <Link to="/start-a-repair" className="px-5 mt-3 mb-5 btn btn-primary">Start a Repair</Link>
        </div>
      </Container>
      <FeaturedServices />
    </Main>
  </Layout>
)

export default RepairServicesIndexPage
